//
// _menu.scss
//

.metismenu {
    margin: 0;

    li {
        display: block;
        width: 100%;

        &.mm-active {
            -moz-box-shadow: inset 0 0 8px #ccc;
            -webkit-box-shadow: inset 0 0 8px #ccc;
            box-shadow: inset 0 0 8px #ccc;
            background-color: #fcfdff;
        }
    }

    .mm-collapse {
        display: none;

        &:not(.mm-show) {
            display: none;
        }

        &.mm-show {
            display: block;
        }
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: 0.35s;
        transition-property: height, visibility;
    }
}

.vertical-menu {
    width: $sidebar-width;
    transition: all 0.2s ease-out;
    z-index: 1001;
    background: $sidebar-bg;
    top: 0;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    border-right: 1px solid $sidebar-border-color;
    // background-image: url('/src/assets/images/bg/1.png'); // can't build
    background-repeat: no-repeat;
    background-position: left top;
    background-attachment: fixed;
    background-size: 220px 100%;
}

body[data-layout-mode="dark"] {
    .main-content {
        background-color: #111A26;
    }
}

.main-content {
    margin-left: $sidebar-width;
    overflow: hidden;
    background-color: #F4F5FD;
    // box-shadow: inset 1px 0px 15px 20px $box-shadow-color-white;
    // -moz-box-shadow: inset 1px 0px 15px 20px $box-shadow-color-white;
    // -webkit-box-shadow: inset 1px 0px 15px 20px $box-shadow-color-white;
    // background-image: url('../../../images/bg/2.png'), url('../../../images/bg/3.png');; // can't build
    background-repeat: no-repeat;
    background-attachment: fixed, fixed;
    background-position: left top, right bottom;
    background-size: contain;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

#sidebar-menu {
    padding: 10px 0 30px 0;

    .mm-active {
        >.has-arrow {
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    .has-arrow {
        &:after {
            content: "\F0142";
            font-family: "Material Design Icons";
            display: block;
            float: right;
            transition: transform 0.2s;
            font-size: 1.1rem;
            margin-right: -5px;
            margin-top: -2px;
        }
    }

    ul {
        li {
            border-radius: 5px;

            a,
            button {
                display: block;
                padding: 0.8rem 1.5rem;
                color: $sidebar-menu-item-color;
                position: relative;
                font-size: 0.9rem;
                transition: all 0.4s;
                font-weight: $font-weight-medium;

                i {
                    display: inline-block;
                    min-width: 1.75rem;
                    padding-bottom: 0.125em;
                    font-size: 1.5rem;
                    line-height: 1.40625rem;
                    vertical-align: middle;
                    transition: all 0.4s;
                    color: $sidebar-menu-item-icon-color;
                    fill: #808191;
                }
                span {
                    margin-left: 6px;
                }

                &:hover {

                    i {
                        color: $sidebar-menu-item-hover-color;
                    }
                }
            }

            .badge {
                margin-top: 4px;
            }

            ul.sub-menu {
                padding: 0;

                li {

                    a,
                    button {
                        padding: 0.4rem 1.5rem 0.4rem 3.3rem;
                        font-size: 0.85rem;
                        color: $sidebar-menu-sub-item-color;

                        &:hover {
                            color: $sidebar-menu-item-hover-color;
                        }
                    }

                    ul.sub-menu {
                        padding: 0;

                        li {
                            a {
                                padding: 0.4rem 1.5rem 0.4rem 4.5rem;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-title {
    padding: 12px 20px !important;
    pointer-events: none;
    cursor: default;
    font-size: 12px;
    color: $sidebar-menu-item-icon-color;
    font-weight: $font-weight-medium;
}

.mm-active {
    >a {

        // color: $sidebar-menu-item-active-color !important;
        i {
            color: $sidebar-menu-item-active-color !important;
        }

        svg {
            // color: $sidebar-menu-item-active-color !important;
            // fill: rgba($sidebar-menu-item-active-color,0.2) !important;
        }
    }

    .active {
        // color: $sidebar-menu-item-active-color !important;

        i {
            color: $sidebar-menu-item-active-color !important;
        }

        svg {
            // color: $sidebar-menu-item-active-color !important;
            // fill: rgba($sidebar-menu-item-active-color,0.2) !important;
        }
    }

    >i {
        color: $sidebar-menu-item-active-color !important;
    }
}

.sidebar-alert {
    background-color: rgba($primary, 0.1);

    .alertcard-title {
        color: $primary;
    }
}

@media (max-width: 992px) {
    .vertical-menu {
        display: none;
    }

    .main-content {
        margin-left: 0 !important;
    }

    body.sidebar-enable {
        .vertical-menu {
            display: block;
        }
    }
}

body[data-sidebar-size="lg"] {
    &.sidebar-enable {
        .navbar-brand-box {
            width: $sidebar-width;
        }

        .logo-container {
            width: 100%;
            display: flex;
            justify-content: center;
            transition: all .4s;
        }

        .main-content {
            margin-left: 250px;
            margin-right: 0px;
        }

        #page-topbar {
            margin-left: 250 !important;
        }

        .vertical-menu {
            display: block;
        }

        .logo-sm {
            display: none;
        }
    }

    &.sidebar-hidden {
        .main-content {
            margin-left: $sidebar-collapsed-width;
            margin-right: 0px;
        }

        #page-topbar {
            margin-left: $sidebar-collapsed-width !important;
        }

        #vertical-menu-btn svg {
            transform: rotate(180deg);
        }

        .logo-big {
            display: none;
        }

        .logo-sm {
            width: $sidebar-collapsed-width !important;
            object-fit: contain;
        }

        // Side menu
        .vertical-menu {
            // position: absolute;
            width: $sidebar-collapsed-width !important;
            z-index: 5;

            .simplebar-mask,
            .simplebar-content-wrapper {
                overflow: visible !important;
            }

            .simplebar-scrollbar {
                display: none !important;
            }

            .simplebar-offset {
                bottom: 0 !important;
            }

            // Sidebar Menu
            #sidebar-menu {

                .menu-title,
                .badge,
                .sidebar-alert {
                    display: none !important;
                }

                .nav.collapse {
                    height: inherit !important;
                }

                >ul {
                    >li {
                        position: relative;
                        white-space: nowrap;
                        padding-left: 0;

                        >a {
                            padding: 15px 20px;
                            transition: none;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $sidebar-menu-item-hover-color;
                            }


                            span {
                                display: none;
                                padding-left: 25px;
                            }

                            &.has-arrow {
                                &:after {
                                    display: none;
                                }
                            }
                        }

                        &:hover {
                            >a {
                                position: relative;
                                width: calc(190px + #{$sidebar-collapsed-width});
                                color: $primary;
                                background-color: darken($sidebar-bg, 4%);
                                transition: none;

                                i {
                                    color: $primary;
                                }

                                svg {
                                    color: $sidebar-menu-item-active-color;
                                    fill: rgba($sidebar-menu-item-active-color, 0.2);
                                }

                                span {
                                    display: inline;
                                }
                            }

                            >ul {
                                display: block;
                                left: $sidebar-collapsed-width;
                                right: $sidebar-collapsed-width;
                                position: absolute;
                                width: 190px;
                                height: auto !important;
                                box-shadow: $box-shadow-lg;

                                ul {
                                    box-shadow: $box-shadow-lg;
                                }

                                a {
                                    box-shadow: none;
                                    padding: 8px 20px;
                                    position: relative;
                                    width: 190px;
                                    z-index: 6;
                                    color: $sidebar-menu-sub-item-color;

                                    &:hover {
                                        color: $sidebar-menu-item-hover-color;
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        padding: 5px 0;
                        z-index: 9999;
                        display: none;
                        background-color: $white;

                        li {
                            &:hover {
                                >ul {
                                    display: block;
                                    left: 190px;
                                    height: auto !important;
                                    margin-top: -36px;
                                    position: absolute;
                                    width: 190px;
                                    padding: 5px 0;
                                }
                            }

                            >a {
                                span.pull-right {
                                    position: absolute;
                                    right: 20px;
                                    top: 12px;
                                    transform: rotate(270deg);
                                }
                            }
                        }

                        li.active {
                            a {
                                color: $gray-100;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Enlarge menu
body[data-sidebar-size="sm"] {
    &.sidebar-enable {
        min-height: 1000px;

        .main-content {
            margin-left: $sidebar-collapsed-width;
        }

        #page-topbar {
            margin-left: $sidebar-collapsed-width !important;
        }

        .navbar-brand-box {
            width: $sidebar-collapsed-width !important;
        }

        .logo-big {
            display: none !important;
        }

        .logo-sm {
            width: $sidebar-collapsed-width !important;
            object-fit: contain;
        }

        // Side menu
        .vertical-menu {
            // position: absolute;
            width: $sidebar-collapsed-width !important;
            z-index: 5;

            .simplebar-mask,
            .simplebar-content-wrapper {
                overflow: visible !important;
            }

            .simplebar-scrollbar {
                display: none !important;
            }

            .simplebar-offset {
                bottom: 0 !important;
            }

            // Sidebar Menu
            #sidebar-menu {

                .menu-title,
                .badge,
                .sidebar-alert {
                    display: none !important;
                }

                .nav.collapse {
                    height: inherit !important;
                }

                >ul {
                    >li {
                        position: relative;
                        white-space: nowrap;
                        padding-left: 0;

                        >a {
                            padding: 15px 20px;
                            transition: none;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $sidebar-menu-item-hover-color;
                            }

                            i {
                                font-size: 1.45rem;
                                margin-left: 4px;
                            }

                            span {
                                display: none;
                                padding-left: 25px;
                            }

                            &.has-arrow {
                                &:after {
                                    display: none;
                                }
                            }
                        }

                        &:hover {
                            >a {
                                position: relative;
                                width: calc(190px + #{$sidebar-collapsed-width});
                                color: $primary;
                                background-color: darken($sidebar-bg, 4%);
                                transition: none;

                                i {
                                    color: $primary;
                                }

                                svg {
                                    color: $sidebar-menu-item-active-color;
                                    fill: rgba($sidebar-menu-item-active-color, 0.2);
                                }

                                span {
                                    display: inline;
                                }
                            }

                            >ul {
                                display: block;
                                left: $sidebar-collapsed-width;
                                position: absolute;
                                width: 190px;
                                height: auto !important;
                                box-shadow: $box-shadow-lg;

                                ul {
                                    box-shadow: $box-shadow-lg;
                                }

                                a {
                                    box-shadow: none;
                                    padding: 8px 20px;
                                    position: relative;
                                    width: 190px;
                                    z-index: 6;
                                    color: $sidebar-menu-sub-item-color;

                                    &:hover {
                                        color: $sidebar-menu-item-hover-color;
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        padding: 5px 0;
                        z-index: 9999;
                        display: none;
                        background-color: $white;

                        li {
                            &:hover {
                                >ul {
                                    display: block;
                                    left: 190px;
                                    height: auto !important;
                                    margin-top: -36px;
                                    position: absolute;
                                    width: 190px;
                                    padding: 5px 0;
                                }
                            }

                            >a {
                                span.pull-right {
                                    position: absolute;
                                    right: 20px;
                                    top: 12px;
                                    transform: rotate(270deg);
                                }
                            }
                        }

                        li.active {
                            a {
                                color: $gray-100;
                            }
                        }
                    }
                }
            }
        }

        #sidebar-menu {
            .mm-active>.has-arrow:after {
                transform: rotate(0deg);
            }
        }
    }

    &.sidebar-hidden {
        .main-content {
            margin-left: 0;
        }

        #page-topbar {
            margin-left: 0;
        }

        #vertical-menu-btn svg {
            transform: rotate(180deg);
        }
    }
}

body[data-sidebar="dark"] {
    .metismenu .mm-active {
        box-shadow: inset 0 0 0px #ccc;
        background-color: $dark-active;
    }

    .navbar-brand-box {
        background: $sidebar-dark-bg;
        box-shadow: 0px 3px 1px $sidebar-dark-bg;
        border-color: $sidebar-dark-bg;

        .logo {
            color: $white !important;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .vertical-menu {
        // background: $sidebar-dark-bg;
        border-color: $sidebar-dark-bg;
    }

    #vertical-menu-btn {
        @media (min-width: 992px) {
            color: $header-dark-item-color;
        }
    }

    #sidebar-menu {
        ul {
            li {
                a {
                    color: $sidebar-dark-menu-item-color;

                    i {
                        color: $sidebar-dark-menu-item-icon-color;
                    }

                    &:hover {
                        color: $sidebar-dark-menu-item-hover-color;

                        i {
                            color: $sidebar-dark-menu-item-hover-color;
                        }

                        svg {
                            color: $sidebar-dark-menu-item-hover-color;
                            fill: rgba($sidebar-dark-menu-item-hover-color, 0.2);
                        }
                    }
                }

                ul.sub-menu {
                    li {
                        a {
                            color: $sidebar-dark-menu-sub-item-color;

                            &:hover {
                                color: $sidebar-dark-menu-item-hover-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // Enlarge menu
    &[data-sidebar-size="sm"] {
        &[data-topbar="dark"] {
            #vertical-menu-btn {
                color: $header-dark-item-color;
            }
        }

        #vertical-menu-btn {
            color: $header-item-color;
        }

        // Side menu
        .vertical-menu {

            // Sidebar Menu
            #sidebar-menu {
                >ul {
                    >li {
                        &:hover {
                            >a {
                                background: lighten($sidebar-dark-bg, 2%);
                                color: $sidebar-dark-menu-item-hover-color;

                                i {
                                    color: $sidebar-dark-menu-item-hover-color;
                                }

                                svg {
                                    color: $sidebar-dark-menu-item-hover-color;
                                    fill: rgba($sidebar-dark-menu-item-hover-color, 0.2);
                                }
                            }

                            >ul {
                                a {
                                    color: $sidebar-dark-menu-sub-item-color;

                                    &:hover {
                                        color: $sidebar-dark-menu-item-hover-color;
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        background-color: $sidebar-dark-bg;
                    }
                }

                ul {
                    li {
                        &.mm-active .active {
                            color: $sidebar-dark-menu-item-active-color !important;

                            i {
                                color: $sidebar-dark-menu-item-active-color !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .mm-active {
        color: $sidebar-dark-menu-item-active-color !important;

        >a {
            color: $sidebar-dark-menu-item-active-color !important;

            i {
                color: $sidebar-dark-menu-item-active-color !important;
            }

            svg {
                color: $sidebar-dark-menu-item-active-color !important;
                fill: rgba($sidebar-dark-menu-item-active-color, 0.2) !important;
            }
        }

        >i {
            color: $sidebar-dark-menu-item-active-color !important;
        }

        .active {
            color: $sidebar-dark-menu-item-active-color !important;

            i {
                color: $sidebar-dark-menu-item-active-color !important;
            }

            svg {
                color: $sidebar-dark-menu-item-active-color !important;
                fill: rgba($sidebar-dark-menu-item-active-color, 0.2) !important;
            }
        }
    }

    .menu-title {
        color: $sidebar-dark-menu-item-icon-color;
    }

    &[data-sidebar-size="md"] {
        #sidebar-menu {
            ul li {
                &.menu-title {
                    background-color: lighten($sidebar-dark-bg, 2%);
                }
            }
        }
    }
}

body[data-layout="horizontal"] {
    .main-content {
        margin-left: 0 !important;
    }
}

// Compact Sidebar

body[data-sidebar-size="md"] {
    .navbar-brand-box {
        width: $sidebar-width-sm;

        @media (max-width: 991.98px) {
            width: auto;
        }
    }

    #page-topbar {
        left: $sidebar-width-sm;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }

    .vertical-menu {
        width: $sidebar-width-sm;
        text-align: center;

        .has-arrow:after,
        .badge,
        .sidebar-alert {
            display: none !important;
        }
    }

    .main-content {
        margin-left: $sidebar-width-sm;
    }

    .footer {
        left: $sidebar-width-sm;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }

    #sidebar-menu {
        ul li {
            a {
                svg {
                    display: block;
                    margin: 0 auto 4px;
                }
            }

            ul.sub-menu {
                li {
                    a {
                        padding-left: 1.5rem;
                    }

                    ul.sub-menu li a {
                        padding-left: 1.5rem;
                    }
                }
            }
        }
    }

    &[data-sidebar-size="sm"] {
        .main-content {
            margin-left: $sidebar-collapsed-width;
        }

        .vertical-menu {
            #sidebar-menu {
                text-align: left;

                >ul {
                    >li {
                        >a {
                            svg {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            left: $sidebar-collapsed-width;
        }
    }
}

// colored sidebar

body[data-sidebar="brand"] {
    .vertical-menu {
        background-color: $primary;
    }

    .navbar-brand-box {
        background-color: $primary;
        box-shadow: 0px 1px 0px $primary;

        .logo-dark {
            display: none;
        }

        .logo-light {
            display: block;
        }

        .logo {
            color: $white !important;
        }
    }

    .mm-active {
        color: $white !important;

        >a {
            color: $white !important;

            i {
                color: $white !important;
            }

            svg {
                color: $white !important;
                fill: rgba($white, 0.1) !important;
            }
        }

        .active {
            color: $white !important;

            svg {
                color: $white !important;
                fill: rgba($white, 0.1) !important;
            }
        }
    }

    #vertical-menu-btn {
        @media (min-width: 992px) {
            color: $header-dark-item-color;
        }
    }

    #sidebar-menu {
        ul {
            li {
                &.menu-title {
                    color: rgba($white, 0.6);
                }

                a {
                    color: rgba($white, 0.6);

                    i {
                        color: rgba($white, 0.6);
                    }

                    svg {
                        color: rgba($white, 0.6);
                        fill: rgba($white, 0.075);
                    }

                    &.waves-effect {
                        .waves-ripple {
                            background: rgba($white, 0.1);
                        }
                    }

                    &:hover {
                        color: $white;

                        i {
                            color: $white;
                        }
                    }
                }

                ul.sub-menu {
                    li {
                        a {
                            color: rgba($white, 0.5);

                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .sidebar-alert {
        background-color: rgba($white, 0.1);
        color: rgba($white, 0.5);

        .alertcard-title {
            color: $white;
        }
    }

    &[data-sidebar-size="sm"] {
        &[data-topbar="dark"] {
            #vertical-menu-btn {
                color: $header-dark-item-color;
            }
        }

        #vertical-menu-btn {
            color: $header-item-color;
        }

        .vertical-menu {
            #sidebar-menu {
                >ul {
                    >li {
                        &:hover>a {
                            background-color: lighten($primary, 2%);
                            color: $white;

                            i,
                            svg {
                                color: $white;
                            }
                        }
                    }
                }

                ul {
                    li {
                        &.mm-active {
                            .active {
                                color: $white !important;
                            }
                        }

                        ul.sub-menu {
                            li {
                                a {
                                    &:hover {
                                        color: $sidebar-menu-item-active-color;
                                    }
                                }

                                &.mm-active {
                                    color: $sidebar-menu-item-active-color !important;

                                    >a {
                                        color: $sidebar-menu-item-active-color !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Dark Layout

body[data-layout-mode="dark"] {
    .main-content {
        background-color: #111A26;
        box-shadow: inset 0 0 0 $box-shadow-color-white;
        -moz-box-shadow: inset 0 0 0 $box-shadow-color-white;
        -webkit-box-shadow: inset 0 0 0 $box-shadow-color-white;
    }

    &[data-sidebar="dark"],
    &[data-sidebar="brand"] {

        .vertical-menu,
        .navbar-brand-box {
            border-color: lighten($gray-dark-200, 2.5%);
            background-color: $main-dark-bg;
            border: 0;
            box-shadow: 0px 0px 0px #2c302e;
        }
    }

    &[data-sidebar="light"] {
        .sidebar-alert {
            background-color: rgba($primary, 0.1);
            color: $body-color;

            .alertcard-title {
                color: $primary;
            }
        }
    }
}

// RTL
[dir="rtl"] {
    #sidebar-menu {
        .has-arrow:after {
            content: "\F0141";
        }
    }
}

.button-transition-right {
    position: relative;
    animation-duration: 0.55s;
    animation-name: move-right;
}

@keyframes move-right {
    100% {
        right: 0%;
    }

    0% {
        right: 100%;
    }
}

.button-transition-left {
    position: relative;
    animation-duration: 0.65s;
    animation-name: move-left;
}

@keyframes move-left {
    100% {
        left: 0%;
    }

    0% {
        left: 100%;
    }
}

.hide-animated {
    width: 0;
    opacity: 0;
    padding: 0 !important
}

.show-animated {
    opacity: 1;
}

.animate-width {
    transition: width 0.5s linear, opacity 0.5s linear;
}